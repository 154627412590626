export const BrandsIndexHero = () => import('../../components/brands/indexHero.vue' /* webpackChunkName: "components/brands-index-hero" */).then(c => wrapFunctional(c.default || c))
export const CaptchaImage = () => import('../../components/captcha/image.vue' /* webpackChunkName: "components/captcha-image" */).then(c => wrapFunctional(c.default || c))
export const CartDomain = () => import('../../components/cart/Domain.vue' /* webpackChunkName: "components/cart-domain" */).then(c => wrapFunctional(c.default || c))
export const CitySelection = () => import('../../components/city/Selection.vue' /* webpackChunkName: "components/city-selection" */).then(c => wrapFunctional(c.default || c))
export const CommonButton = () => import('../../components/common/Button.vue' /* webpackChunkName: "components/common-button" */).then(c => wrapFunctional(c.default || c))
export const CommonCard = () => import('../../components/common/Card.vue' /* webpackChunkName: "components/common-card" */).then(c => wrapFunctional(c.default || c))
export const CommonDebugBanner = () => import('../../components/common/DebugBanner.vue' /* webpackChunkName: "components/common-debug-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonEmpty = () => import('../../components/common/Empty.vue' /* webpackChunkName: "components/common-empty" */).then(c => wrapFunctional(c.default || c))
export const CommonFooter = () => import('../../components/common/Footer.vue' /* webpackChunkName: "components/common-footer" */).then(c => wrapFunctional(c.default || c))
export const CommonFooterAdvertisement = () => import('../../components/common/FooterAdvertisement.vue' /* webpackChunkName: "components/common-footer-advertisement" */).then(c => wrapFunctional(c.default || c))
export const CommonFooterMenu = () => import('../../components/common/FooterMenu.vue' /* webpackChunkName: "components/common-footer-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonIcon = () => import('../../components/common/Icon.vue' /* webpackChunkName: "components/common-icon" */).then(c => wrapFunctional(c.default || c))
export const CommonIconfont = () => import('../../components/common/Iconfont.vue' /* webpackChunkName: "components/common-iconfont" */).then(c => wrapFunctional(c.default || c))
export const CommonLocked = () => import('../../components/common/Locked.vue' /* webpackChunkName: "components/common-locked" */).then(c => wrapFunctional(c.default || c))
export const CommonLogo = () => import('../../components/common/Logo.vue' /* webpackChunkName: "components/common-logo" */).then(c => wrapFunctional(c.default || c))
export const CommonNav = () => import('../../components/common/Nav.vue' /* webpackChunkName: "components/common-nav" */).then(c => wrapFunctional(c.default || c))
export const CommonPagenationLink = () => import('../../components/common/PagenationLink.vue' /* webpackChunkName: "components/common-pagenation-link" */).then(c => wrapFunctional(c.default || c))
export const CommonPrivacyCheck = () => import('../../components/common/PrivacyCheck.vue' /* webpackChunkName: "components/common-privacy-check" */).then(c => wrapFunctional(c.default || c))
export const EasyGoTop = () => import('../../components/easy_go_top/EasyGoTop.vue' /* webpackChunkName: "components/easy-go-top" */).then(c => wrapFunctional(c.default || c))
export const EasyGoTopMixins = () => import('../../components/easy_go_top/mixins.js' /* webpackChunkName: "components/easy-go-top-mixins" */).then(c => wrapFunctional(c.default || c))
export const DomainTypeText = () => import('../../components/domain/TypeText.vue' /* webpackChunkName: "components/domain-type-text" */).then(c => wrapFunctional(c.default || c))
export const EditorAdminOptions = () => import('../../components/editor/AdminOptions.vue' /* webpackChunkName: "components/editor-admin-options" */).then(c => wrapFunctional(c.default || c))
export const EditorCustomKeywords = () => import('../../components/editor/CustomKeywords.vue' /* webpackChunkName: "components/editor-custom-keywords" */).then(c => wrapFunctional(c.default || c))
export const EditorKeywordIndexGen = () => import('../../components/editor/KeywordIndexGen.vue' /* webpackChunkName: "components/editor-keyword-index-gen" */).then(c => wrapFunctional(c.default || c))
export const EditorOrigin = () => import('../../components/editor/Origin.vue' /* webpackChunkName: "components/editor-origin" */).then(c => wrapFunctional(c.default || c))
export const EditorQuillEditor = () => import('../../components/editor/QuillEditor.vue' /* webpackChunkName: "components/editor-quill-editor" */).then(c => wrapFunctional(c.default || c))
export const EditorRecentThreads = () => import('../../components/editor/RecentThreads.vue' /* webpackChunkName: "components/editor-recent-threads" */).then(c => wrapFunctional(c.default || c))
export const EditorSaveButtons = () => import('../../components/editor/SaveButtons.vue' /* webpackChunkName: "components/editor-save-buttons" */).then(c => wrapFunctional(c.default || c))
export const EditorStudioEditor = () => import('../../components/editor/StudioEditor.vue' /* webpackChunkName: "components/editor-studio-editor" */).then(c => wrapFunctional(c.default || c))
export const EmojiSelector = () => import('../../components/emoji/Selector.vue' /* webpackChunkName: "components/emoji-selector" */).then(c => wrapFunctional(c.default || c))
export const FilePicker = () => import('../../components/file/picker.vue' /* webpackChunkName: "components/file-picker" */).then(c => wrapFunctional(c.default || c))
export const IndexCategoryTag = () => import('../../components/index/CategoryTag.vue' /* webpackChunkName: "components/index-category-tag" */).then(c => wrapFunctional(c.default || c))
export const IndexHeroGalleries = () => import('../../components/index/HeroGalleries.vue' /* webpackChunkName: "components/index-hero-galleries" */).then(c => wrapFunctional(c.default || c))
export const IndexNewLanding = () => import('../../components/index/NewLanding.vue' /* webpackChunkName: "components/index-new-landing" */).then(c => wrapFunctional(c.default || c))
export const MobileBottomNavigator = () => import('../../components/mobile/BottomNavigator.vue' /* webpackChunkName: "components/mobile-bottom-navigator" */).then(c => wrapFunctional(c.default || c))
export const PageCardTile = () => import('../../components/page/CardTile.vue' /* webpackChunkName: "components/page-card-tile" */).then(c => wrapFunctional(c.default || c))
export const PaymentGatewaySelection = () => import('../../components/payment/GatewaySelection.vue' /* webpackChunkName: "components/payment-gateway-selection" */).then(c => wrapFunctional(c.default || c))
export const PexelsFlatSearch = () => import('../../components/pexels/FlatSearch.vue' /* webpackChunkName: "components/pexels-flat-search" */).then(c => wrapFunctional(c.default || c))
export const PostsEditor = () => import('../../components/posts/Editor.vue' /* webpackChunkName: "components/posts-editor" */).then(c => wrapFunctional(c.default || c))
export const PostsFirstPageList = () => import('../../components/posts/FirstPageList.vue' /* webpackChunkName: "components/posts-first-page-list" */).then(c => wrapFunctional(c.default || c))
export const PostsListTile = () => import('../../components/posts/ListTile.vue' /* webpackChunkName: "components/posts-list-tile" */).then(c => wrapFunctional(c.default || c))
export const ProductLandingTemplate = () => import('../../components/product/LandingTemplate.vue' /* webpackChunkName: "components/product-landing-template" */).then(c => wrapFunctional(c.default || c))
export const QuestionAnswerList = () => import('../../components/question/AnswerList.vue' /* webpackChunkName: "components/question-answer-list" */).then(c => wrapFunctional(c.default || c))
export const QuestionHasNoPickedAnswerList = () => import('../../components/question/HasNoPickedAnswerList.vue' /* webpackChunkName: "components/question-has-no-picked-answer-list" */).then(c => wrapFunctional(c.default || c))
export const QuestionHasPickedAnswerList = () => import('../../components/question/HasPickedAnswerList.vue' /* webpackChunkName: "components/question-has-picked-answer-list" */).then(c => wrapFunctional(c.default || c))
export const QuestionQuickActions = () => import('../../components/question/QuickActions.vue' /* webpackChunkName: "components/question-quick-actions" */).then(c => wrapFunctional(c.default || c))
export const RobotOnly = () => import('../../components/robot/Only.vue' /* webpackChunkName: "components/robot-only" */).then(c => wrapFunctional(c.default || c))
export const SearchFlatTextfield = () => import('../../components/search/FlatTextfield.vue' /* webpackChunkName: "components/search-flat-textfield" */).then(c => wrapFunctional(c.default || c))
export const SetSiteCloseSite = () => import('../../components/setSite/CloseSite.vue' /* webpackChunkName: "components/set-site-close-site" */).then(c => wrapFunctional(c.default || c))
export const SmsSendButton = () => import('../../components/sms/SendButton.vue' /* webpackChunkName: "components/sms-send-button" */).then(c => wrapFunctional(c.default || c))
export const TermsSdkList = () => import('../../components/terms/SdkList.vue' /* webpackChunkName: "components/terms-sdk-list" */).then(c => wrapFunctional(c.default || c))
export const ThreadsAuthor = () => import('../../components/threads/Author.vue' /* webpackChunkName: "components/threads-author" */).then(c => wrapFunctional(c.default || c))
export const ThreadsCarousel = () => import('../../components/threads/Carousel.vue' /* webpackChunkName: "components/threads-carousel" */).then(c => wrapFunctional(c.default || c))
export const ThreadsCategoryActions = () => import('../../components/threads/CategoryActions.vue' /* webpackChunkName: "components/threads-category-actions" */).then(c => wrapFunctional(c.default || c))
export const ThreadsCategorySelector = () => import('../../components/threads/CategorySelector.vue' /* webpackChunkName: "components/threads-category-selector" */).then(c => wrapFunctional(c.default || c))
export const ThreadsContentDetailActions = () => import('../../components/threads/ContentDetailActions.vue' /* webpackChunkName: "components/threads-content-detail-actions" */).then(c => wrapFunctional(c.default || c))
export const ThreadsCoverImageEditor = () => import('../../components/threads/CoverImageEditor.vue' /* webpackChunkName: "components/threads-cover-image-editor" */).then(c => wrapFunctional(c.default || c))
export const ThreadsDraftList = () => import('../../components/threads/DraftList.vue' /* webpackChunkName: "components/threads-draft-list" */).then(c => wrapFunctional(c.default || c))
export const ThreadsListTile = () => import('../../components/threads/ListTile.vue' /* webpackChunkName: "components/threads-list-tile" */).then(c => wrapFunctional(c.default || c))
export const ThreadsPoster = () => import('../../components/threads/Poster.vue' /* webpackChunkName: "components/threads-poster" */).then(c => wrapFunctional(c.default || c))
export const ThreadsPremiumTag = () => import('../../components/threads/PremiumTag.vue' /* webpackChunkName: "components/threads-premium-tag" */).then(c => wrapFunctional(c.default || c))
export const ThreadsQuickActions = () => import('../../components/threads/QuickActions.vue' /* webpackChunkName: "components/threads-quick-actions" */).then(c => wrapFunctional(c.default || c))
export const ThreadsRecommends = () => import('../../components/threads/Recommends.vue' /* webpackChunkName: "components/threads-recommends" */).then(c => wrapFunctional(c.default || c))
export const ThreadsReviewListTile = () => import('../../components/threads/ReviewListTile.vue' /* webpackChunkName: "components/threads-review-list-tile" */).then(c => wrapFunctional(c.default || c))
export const TldHorizontalRecommends = () => import('../../components/tld/HorizontalRecommends.vue' /* webpackChunkName: "components/tld-horizontal-recommends" */).then(c => wrapFunctional(c.default || c))
export const TldSearchBanner = () => import('../../components/tld/SearchBanner.vue' /* webpackChunkName: "components/tld-search-banner" */).then(c => wrapFunctional(c.default || c))
export const ToolsBreadcrumb = () => import('../../components/tools/Breadcrumb.vue' /* webpackChunkName: "components/tools-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const UserAvatar = () => import('../../components/user/Avatar.vue' /* webpackChunkName: "components/user-avatar" */).then(c => wrapFunctional(c.default || c))
export const UserInfoList = () => import('../../components/user/InfoList.vue' /* webpackChunkName: "components/user-info-list" */).then(c => wrapFunctional(c.default || c))
export const UserStatusButton = () => import('../../components/user/StatusButton.vue' /* webpackChunkName: "components/user-status-button" */).then(c => wrapFunctional(c.default || c))
export const DomainTradeNextAndPrev = () => import('../../components/domain/trade/NextAndPrev.vue' /* webpackChunkName: "components/domain-trade-next-and-prev" */).then(c => wrapFunctional(c.default || c))
export const DomainTradeSearchBanner = () => import('../../components/domain/trade/SearchBanner.vue' /* webpackChunkName: "components/domain-trade-search-banner" */).then(c => wrapFunctional(c.default || c))
export const DomainTradeSortTypeSelector = () => import('../../components/domain/trade/SortTypeSelector.vue' /* webpackChunkName: "components/domain-trade-sort-type-selector" */).then(c => wrapFunctional(c.default || c))
export const DomainTradeTldsSelector = () => import('../../components/domain/trade/TldsSelector.vue' /* webpackChunkName: "components/domain-trade-tlds-selector" */).then(c => wrapFunctional(c.default || c))
export const EditorFaqAttachmentUploader = () => import('../../components/editor/faq/AttachmentUploader.vue' /* webpackChunkName: "components/editor-faq-attachment-uploader" */).then(c => wrapFunctional(c.default || c))
export const EditorFaqFull = () => import('../../components/editor/faq/Full.vue' /* webpackChunkName: "components/editor-faq-full" */).then(c => wrapFunctional(c.default || c))
export const EditorFaqTextArea = () => import('../../components/editor/faq/TextArea.vue' /* webpackChunkName: "components/editor-faq-text-area" */).then(c => wrapFunctional(c.default || c))
export const QuestionDetailNextAndPrev = () => import('../../components/question/detail/NextAndPrev.vue' /* webpackChunkName: "components/question-detail-next-and-prev" */).then(c => wrapFunctional(c.default || c))
export const ThreadsDetailNextAndPrev = () => import('../../components/threads/detail/NextAndPrev.vue' /* webpackChunkName: "components/threads-detail-next-and-prev" */).then(c => wrapFunctional(c.default || c))
export const ThreadsTagFlatList = () => import('../../components/threads/tag/FlatList.vue' /* webpackChunkName: "components/threads-tag-flat-list" */).then(c => wrapFunctional(c.default || c))
export const ThreadsUserRecentList = () => import('../../components/threads/user/RecentList.vue' /* webpackChunkName: "components/threads-user-recent-list" */).then(c => wrapFunctional(c.default || c))
export const UserAddressAdd = () => import('../../components/user/address/add.vue' /* webpackChunkName: "components/user-address-add" */).then(c => wrapFunctional(c.default || c))
export const UserAddressList = () => import('../../components/user/address/list.vue' /* webpackChunkName: "components/user-address-list" */).then(c => wrapFunctional(c.default || c))
export const UserDetailHeader = () => import('../../components/user/detail/Header.vue' /* webpackChunkName: "components/user-detail-header" */).then(c => wrapFunctional(c.default || c))
export const UserLoginTab = () => import('../../components/user/login/Tab.vue' /* webpackChunkName: "components/user-login-tab" */).then(c => wrapFunctional(c.default || c))
export const UserSettingsMenus = () => import('../../components/user/settings/Menus.vue' /* webpackChunkName: "components/user-settings-menus" */).then(c => wrapFunctional(c.default || c))
export const UserSettingsProfilesEditor = () => import('../../components/user/settings/ProfilesEditor.vue' /* webpackChunkName: "components/user-settings-profiles-editor" */).then(c => wrapFunctional(c.default || c))
export const UserWalletCard = () => import('../../components/user/wallet/Card.vue' /* webpackChunkName: "components/user-wallet-card" */).then(c => wrapFunctional(c.default || c))
export const WbsSslBuyPlanButton = () => import('../../components/wbs/ssl/BuyPlanButton.vue' /* webpackChunkName: "components/wbs-ssl-buy-plan-button" */).then(c => wrapFunctional(c.default || c))
export const WbsSslPlanList = () => import('../../components/wbs/ssl/PlanList.vue' /* webpackChunkName: "components/wbs-ssl-plan-list" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
