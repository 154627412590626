// 用于全局SEO的关键字
// 注意除了自己想要的词汇外使用指数词才能得到流量
const keywords = [
  "名翠软件",
  "名翠软件",
  "名翠",
  "域名注册",
  "域名服务",
  "域名交易",
  "一口价域名",
  "云南云服务",
  "免费web云监控",
  "http监控",
  "证书监控",
  "德宏软件公司",
  "德宏建站",
  "德宏软件开发",
];


module.exports = {
  // 站点标题
  title: "名翠软件",
  // 站点主域名，仅作为logo渲染
  // 不要携带协议和路径，仅保留主域名
  titleDomain: '开发新动力',
  // 填写域名后缀
  titleDomainTLD: 'mingcui.cn',
  // base host
  baseHost: "https://www.mingcui.cn",
  // 开发环境登录授权代理域名
  proxyLoginBaseUrl: "https://www.mingcui.cn",
  // 生产接口域名
  apiBaseUrl: "https://www.mingcui.cn/api",
  // api内网域名
  // 在生产环境中当api使用Node进行服务端渲染请求时将使用内网api地址进行请求
  apiInternalBaseUrl: "http://10.0.20.12:1299/api",
  // 生产环境下cdn域名
  // 仅涉及前端部署静态资源，请勿作为文件域名
  cdnDomain: "https://cdn.mingcui.cn",
  //  在首页中，将与站点名称组合显示
  slogan: "带德宏软件走向世界",
  // 关键字
  keywords: keywords.map(it => it).join(","),
  // 应用描述
  description: "名翠软件为用户输出生产级别的应用型云计算软件，为站长和开发者提供有价值的软件及工具服务。",
  // 应用作者
  author: "盈江县名软件工作室",
  // 导航菜单
  nav: [
    // { name: "首页", path: "/" },
    // { name: "域名服务", path: "/product/domain" },
    // { name: "网站监控", path: "/product/wbs" },
    // { name: "一口价域名", path: "/product/trade" },
    { name: "开发者专栏", path: "/threads/all" },
    // { name: "美丽记账", path: "/meili", native: true },
    // { name: "问答", path: "/faq" },
    // { name: "TLD百科", path: "https://wiki.mingcui.cn" , native: true},
  ],
  /**
   * 取网页标题
   * @param {*} t 
   * @returns 
   */
  getTitle(t) {
    const { title } = this;
    return `${t || '未知页面'} - ${title}`;
  },
}